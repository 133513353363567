import "twin.macro";
import { ReactFCWithChildren } from "../../types/react";

import { ReactComponent as PowerIcon } from "../assets/svgs/power.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const DashboardLayout: ReactFCWithChildren = ({ children }) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const lT = localStorage.getItem("location");
  const location = lT ? JSON.parse(lT) : "";

  useEffect(() => {
    if (!token && !location) {
      navigate("/login");
    }
  }, [navigate, token, location]);

  return (
    <div tw=''>
      <div tw=' px-64'>
        <div className="wrapper" tw='pt-32 w-full flex justify-between '>
          <h1 tw='text-d-sm font-semibold text-slate/10'>
            {location?.business_name}
          </h1>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
            tw='flex items-center text-slate/06 cursor-pointer self-end'
          >
            <PowerIcon />
            <span tw='inline-block pl-8'>Logout</span>
          </div>
        </div>
        <div tw='pt-32'>{children}</div>
      </div>
    </div>
  );
};
