import numeral from "numeral";
export const generateAuthenticationToken = (): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  let result = "";
  const currentTime = `${Date.now()}`;
  const charactersLength = characters.length;

  for (let i = 0; i < currentTime.length * 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    if (i === currentTime.length) {
      result += currentTime.substring(0, 4);
    }
    if (i === currentTime.length * 2) {
      result += currentTime.substring(4, 8);
    }
    if (i === currentTime.length * 3) {
      result += currentTime.substring(8, currentTime.length);
    }
  }
  return result;
};

export const formatMoney = (x: number) => {
  // convert to naira
  x = x / 100;

  let prefix = "";
  if (x < 0) {
    prefix = "- ";
  }

  const formatted = numeral(Math.abs(x)).format("0,0.00", Math.ceil);
  return `${prefix}NGN ${formatted}`;
};


export const Sleep = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}