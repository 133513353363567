import { QueryFunctionContext, useQuery } from "react-query";
import api from "./axios";
import { QUERY_KEY, STALE_TIME } from "./_keys";
import {
  APIResponse,
  APIResponsePaged,
  PageParams,
  TransactionCategories,
} from "./_types";

////////////////////////////////////////////////////////
//////////////////---INTERFACES---/////////////////////
///////////////////////////////////////////////////////

export interface ITransaction {
  reference: string;
  description: string;
  amount: number;
  date: Date;
  logo: string;
  status: string;
  type: "credit" | "debit";
  session_id: string;
  from: string;
  to: string;
  details: IDetail[];
}

export interface ITransactionCategory {
  uuid: string;
  name: string;
}

interface IDetail {
  label: string;
  value: string;
}

export interface ITransactionParams extends PageParams {
  start_date?: string;
  end_date?: string;
  min_amount?: string | number;
  max_amount?: string | number;
  type?: "credit" | "debit";
  category?: TransactionCategories;
  location_id?: string;
  search?: string;
}

interface IAccountDetails {
  account: {
    account_number: string;
    account_name: string;
    bank: string;
  };
}

////////////////////////////////////////////////////////
////////////////////---API CALLS---/////////////////////
////////////////////////////////////////////////////////

export const fetchTransactions = async ({
  queryKey: [, params],
}: QueryFunctionContext<[string, ITransactionParams?]>) => {
  const { data } = await api.get<APIResponsePaged<ITransaction[]>>(
    "/transactions",
    {
      params,
    }
  );
  return data;
};

export const fetchTransactionCategories = async () => {
  const { data } = await api.get<APIResponse<ITransactionCategory[]>>(
    "/transactions/categories"
  );
  return data.data;
};

export const getLocationAccountDetails = async () => {
  const { data } = await api.get<APIResponse<IAccountDetails>>(
    "/business-location/balance"
  );

  return data.data.account;
};

////////////////////////////////////////////////////////
//////////////////////---HOOKS---///////////////////////
////////////////////////////////////////////////////////

export const useFetchTransactions = (
  params: ITransactionParams,
  enabled = true
) => {
  return useQuery([QUERY_KEY.QUERY_TRANSACTIONS, params], fetchTransactions, {
    staleTime: STALE_TIME,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: enabled,
  });
};

export const useFetchAccountDetails = (enabled = true) => {
  return useQuery(
    [QUERY_KEY.QUERY_LOCATION_ACCOUNT],
    getLocationAccountDetails,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
