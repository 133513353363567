import "twin.macro";
import { io } from "socket.io-client";
import debounce from "lodash.debounce";
import { useQueryClient } from "react-query";
import { Input, TransactionLoader } from "../../components";
import {
  ITransactionParams,
  useFetchTransactions,
} from "../../api/transactions";
import dayjs from "dayjs";
import { Sleep, formatMoney } from "../../utils/utils";
import { useEffect, useMemo, useState } from "react";
import { QUERY_KEY } from "../../api/_keys";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import api from "../../api/axios";
import { EodData } from "@api/auth";
import { useNavigate } from "react-router-dom";

export const Transactions = () => {
  const queryClient = useQueryClient();
  const [eodDate, setEodDate] = useState<Date | null>(new Date());
  const [printTitle, setPrintTitle] = useState("Card Transactions");
  const [eod, setEod] = useState<EodData | null>();
  const navigate = useNavigate();
  const socket = io(process.env.REACT_APP_BASE_URL || "");
  const lT = localStorage.getItem("location");
  const location = lT ? JSON.parse(lT) : "";
  const [transactionParams, setTransactionParams] =
    useState<ITransactionParams>({
      type: "credit",
      perPage: 30,
      page: 1,
    });

  const { data, isLoading } = useFetchTransactions(transactionParams);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setTransactionParams({
        ...transactionParams,
        search: e.target.value,
      });
    } else {
      setTransactionParams({
        type: "credit",
        perPage: 30,
        page: 1,
      });
    }
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  socket.on("transactions_update", (location_id) => {
    if (location?.location_id === location_id) {
      queryClient.invalidateQueries(QUERY_KEY.QUERY_TRANSACTIONS);
    }
  });

  useEffect(() => {
    setTransactionParams({
      type: "credit",
      perPage: 30,

      page: 1,
    });
  }, []);

  const fetchEod = async (type: "bank" | "card") => {
    if (type === "card") {
      setPrintTitle("Card Transactions");
    } else {
      setPrintTitle("Bank Transfers");
    }

    const { data } = await api.post(`/pos/eod`, {
      date: dayjs(eodDate).format("YYYY-MM-DD"),
      type,
    });

    const eod: EodData = data.data;

    var styles = `
    @media print {
      @page {
        size: 80mm ${140 + 7 * eod.data.length}mm;
        margin: 0;
      }
    }
`;

    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    setEod(eod);
  };

  useEffect(() => {
    const l = localStorage.getItem("location");

    if (!l) {
      navigate("/login");
    }
  }, [navigate]);

  const accountDetails: {
    location_id: string;
    business_name: string;
    location_name: string;
    account: {
      account: string;
      bank: string;
      name: string;
    };
    location: {
      uuid: string;
      business_id: string;
      login_token: string;
      name: string;
      enable_catalog: boolean;
      zip_code: null;
      country: null;
      latitude: null;
      longitude: null;
      business: {
        name: string;
      };
    };
    terminal_id: string;
    nomba_terminal_id: string;
    terminal_sn: string;
    sim_sn: string;
  } = useMemo(() => JSON.parse(localStorage.getItem("location") ?? "{}"), []);

  return (
    <>
      <div className="receipt">
        <h3 tw="text-sm font-bold text-center">{eod?.merchant_name}</h3>
        <div tw="mt-24">
          <div tw="flex items-center justify-between mb-4">
            <p>Date/Time</p>
            <p>{dayjs().format("MMM DD YYYY hh:mmA")}</p>
          </div>
          <div tw="flex items-center justify-between mb-4">
            <p>Terminal ID</p>
            <p>{accountDetails?.nomba_terminal_id}</p>
          </div>
          <div tw="flex items-center justify-between mb-4">
            <p>Merchant ID</p>
            <p>{eod?.merchant_id}</p>
          </div>
        </div>

        <h3 tw="text-md font-bold text-center mt-16">Details Report</h3>
        <h3 tw="text-sm font-bold text-center">
          {dayjs(eodDate).format("YYYY-MM-DD")}
        </h3>
        <h3 tw="text-sm font-bold text-center mt-4">
          ***************************************
        </h3>
        <h3 tw="text-lg font-bold text-center">{printTitle}</h3>
        <h3 tw="text-sm font-bold text-center">
          ***************************************
        </h3>
        <div tw="mt-24">
          <div tw=" border-0 border-b border-solid p-4 flex items-center justify-between">
            <div tw="w-58 font-semibold">Time</div>
            <div tw="w-86 font-semibold">ID</div>
            <div tw="flex-1 font-semibold  text-right">Amount</div>
            <div tw="w-20 font-semibold text-right">ST</div>
          </div>
          {eod?.data?.map((e) => (
            <div tw=" border-0 border-b border-solid p-4 flex items-center justify-between">
              <div tw="w-58">{e.time}</div>
              <div tw="w-86">{e.account}</div>
              <div tw="flex-1 text-right">{e.amount}</div>
              <div tw="w-20 text-right">{e.status}</div>
            </div>
          ))}
        </div>
        <div tw="mt-24">
          <div tw="flex items-center justify-between mb-16">
            <p>No of Transactions</p>
            <p>{eod?.no_of_transactions}</p>
          </div>
          <div tw="flex items-center justify-between mb-4">
            <p>No of Successful Transactions</p>
            <p>{eod?.no_of_successful_transactions}</p>
          </div>
          <div tw="flex items-center justify-between mb-4">
            <p>No of Failed Transactions</p>
            <p>{eod?.no_of_failed_transactions}</p>
          </div>
          <div tw="flex items-center justify-between mb-4">
            <p>Successful Amount</p>
            <p>{eod?.successful_amount}</p>
          </div>
          <div tw="flex items-center justify-between mb-4">
            <p>Failed Amount</p>
            <p>{eod?.failed_amount}</p>
          </div>
        </div>
      </div>
      <section className="wrapper">
        <div>
          <h1 tw="text-md font-medium text-slate/06">
            {accountDetails?.account?.bank}
          </h1>
          <h2 tw="text-d-md font-semibold pb-24">
            {accountDetails?.account?.account}
          </h2>
          <h3 tw="text-sm font-bold">{accountDetails?.account?.name}</h3>
        </div>
        <div tw="pt-56 ">
          <div tw="flex justify-between items-center ">
            <div tw="w-304">
              <Input
                type="text"
                onChange={debouncedResults}
                placeholder="Search Transfers"
              />
            </div>
            <div tw="flex items-center">
              <DatePicker
                selected={eodDate}
                onChange={(date) => setEodDate(date)}
                maxDate={new Date()}
              />
              <button
                tw="bg-blue/07 text-white flex items-center border-0 justify-center h-40 px-16 rounded-lg"
                onClick={async () => {
                  await fetchEod("bank");
                  await Sleep(500);
                  window.print();
                }}
              >
                Print EOD Bank Transfers
              </button>
              <button
                tw="bg-blue/07 text-white flex items-center border-0 justify-center h-40 px-16 rounded-lg ml-16"
                onClick={async () => {
                  await fetchEod("card");
                  await Sleep(500);
                  window.print();
                }}
              >
                Print EOD Card
              </button>
            </div>
          </div>

          <div tw="pt-28 pb-44">
            {isLoading ? (
              <TransactionLoader />
            ) : (
              data?.data?.map((txn) => (
                <div
                  key={txn.reference}
                  tw="flex justify-between border-b last:border-none py-16 border-slate/03"
                >
                  <div tw="flex">
                    <div tw="pr-16">
                      <img src={txn.logo} alt="Bank logo" tw="w-40 h-40" />
                    </div>
                    <div>
                      <p tw="text-md font-semibold text-slate/09">
                        {formatMoney(txn.amount)} | {txn.from}
                      </p>
                      <p tw="text-sm text-slate/07 font-medium">
                        {txn.description}
                      </p>
                    </div>
                  </div>
                  <p tw="font-medium text-slate/06">
                    {dayjs(txn.date).format("hh:mm a | DD MMM, YYYY")}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
};
