import { createGlobalStyle } from "styled-components";
import tw, { GlobalStyles as BaseStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`
html, body {
  overscroll-behavior-y: none;
}
body {
  font-size: 10px;
  ${tw`antialiased font-serif text-xs`}
}

.receipt {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 1.6rem;
}

@media print {
  .wrapper {
    display: none !important;
  }
  .receipt {
    display: block;
    margin: 0;
  }
  @page {
    size: 80mm 110mm;
    margin: 0;
  }
}
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
