import { errorHandler } from "../utils/error";
import { useMutation } from "react-query";
import api from "./axios";
import { APIResponse } from "./_types";
import { toast } from "react-hot-toast";

////////////////////////////////////////////////////////
//////////////////---INTERFACES---/////////////////////
///////////////////////////////////////////////////////

export interface IAuthorizeLocation {
  terminal_id: string;
}


export interface EodData {
  print:                         boolean;
  merchant_name:                 string;
  data_type:                     string;
  bank:                          string;
  account:                       string;
  date_of_print:                 string;
  terminal_id:                   string;
  merchant_id:                   string;
  date:                          Date;
  title:                         string;
  type:                          string;
  no_of_transactions:            string;
  no_of_successful_transactions: string;
  no_of_failed_transactions:     string;
  successful_amount:             string;
  failed_amount:                 string;
  data:                          Datum[];
}

export interface Datum {
  time:    string;
  account: string;
  amount:  string;
  status:  Status;
}

export enum Status {
  F = "F",
  S = "S",
}
////////////////////////////////////////////////////////
////////////////////---API CALLS---/////////////////////
////////////////////////////////////////////////////////

export const authorizeLocation = async (payload: IAuthorizeLocation) => {
  const { data } = await api.post<APIResponse>("/auth/terminal", payload);
  return data;
};

////////////////////////////////////////////////////////
//////////////////////---HOOKS---///////////////////////
////////////////////////////////////////////////////////

export const useAuthorizeLocation = (handleSuccess: () => void) => {
  return useMutation(authorizeLocation, {
    onSuccess: (response) => {
      toast.success(response.message);
      const data = response.data;      
      if (data.terminal_sn) {
        localStorage.setItem("token", data.terminal_sn);
        localStorage.setItem("location", JSON.stringify(data));
      }
      handleSuccess();
    },
    onError: (error) => {
      errorHandler(error);
    },
  });
};
