import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "twin.macro";
import { useAuthorizeLocation } from "../../../api/auth";
import { Button, Input } from "../../../components";
import { AuthLayout } from "../../../layout/AuthLayout";

type FormValues = {
  token: string;
};

export const Login = () => {
  const { setValue, handleSubmit } = useForm<FormValues>();
  const navigate = useNavigate();

  const handleSuccess = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const { mutate, isLoading } = useAuthorizeLocation(handleSuccess);

  const onSubmit = (values: FormValues) => {
    const { token } = values;

    mutate({
      terminal_id: token,
    });
  };

  return (
    <AuthLayout>
      <h1 tw='text-slate/10 text-d-sm font-semibold'>Welcome Back</h1>
      <h2 tw='text-slate/07 max-w-[320px] text-sm leading-[24px] pt-8 pb-32'>
        Enter your 8 digit code
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='Code'
          type='text'
          placeholder='0A00Y000'
          id='code'
          onChange={(e) => {
            setValue("token", e.target.value);
          }}
        />
        <div tw='pt-32'>
          <Button isDisabled={isLoading} type='submit'>
            Login
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};
